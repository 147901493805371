<template>
  <b-container
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          label="Search"
          label-for="filter_search"
          label-class="font-weight-bolder"
        >
          <b-input
            type="text"
            placeholder="search here"
            :disabled="state.busy"
            @change="onTableTicketCategoryRefresh"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-button
          type="button"
          variant="success"
          @click="onShowAddForm"
        >
          Add Ticket Category
        </b-button>
      </b-col>

      <b-col
        cols="12"
        class="mt-3"
      >
        <b-table
          ref="tableTicketCategories"
          hover
          responsive
          show-empty
          :per-page="tableTicketCategories.perPage"
          :current-page="tableTicketCategories.currentPage"
          :items="tableTicketCategoriesProvider"
          :fields="tableTicketCategories.fields"
          :sort-by.sync="tableTicketCategories.sortBy"
          :sort-desc.sync="tableTicketCategories.sortDesc"
          :sort-direction="tableTicketCategories.sortDirection"
          :filter="tableTicketCategories.filter"
          :filter-included-fields="tableTicketCategories.filterOn"
        >
          <template #cell(index)="row">
            {{ tableTicketCategories.currentPage * tableTicketCategories.perPage - tableTicketCategories.perPage + (row.index + 1) }}
          </template>

          <template #cell(active)="row">
            {{ row.item.active == 1 ? "Yes" : "No" }}
          </template>

          <template #cell()="row">
            <div class="text-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-nowrap">
              <b-button
                size="sm"
                @click="onShowEditForm(row.item)"
              >
                Edit
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <div class="w-100 w-sm-25 mb-2 sm-mb-2">
              <b-select
                v-model="tableTicketCategories.perPage"
                :options="tableTicketCategories.pageOptions"
                size="sm"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="tableTicketCategories.currentPage"
              :total-rows="tableTicketCategories.totalRows"
              :per-page="tableTicketCategories.perPage"
              first-number
              last-number
              pills
              prev-text="Prev"
              next-text="Next"
              aria-controls="table"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <b-modal
      id="modal-ticket-category"
      scrollable
      no-close-on-backdrop
      :title="ModalTicketCategoryTitle"
      @ok="onValidateFormTicketCategory"
    >
      <ValidationObserver
        ref="formTicketCategory"
      >
        <b-form
          role="form"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="category name"
                vid="category_name"
                rules="required|max:200"
              >
                <b-form-group
                  label="Category Name"
                  label-for="category_name"
                  label-class="font-weight-bolder"
                >
                  <b-input
                    id="category_name"
                    v-model="ticket_category.category_name"
                    type="text"
                    placeholder="enter category name here"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { AxiosError, AdminTicketCategoryService } from '@/services'
import { core } from '@/config/pluginInit'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminTicketCategories',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Ticket Categories'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      ticket_category: {
        id: null,
        category_name: null,
        active: 0
      },
      tableTicketCategories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'category_name', class: 'font-weight-bolder', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    ModalTicketCategoryTitle  () {
      return this.state.editing ? 'Edit Ticket Category' : 'Add Ticket Category'
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tableTicketCategoriesProvider (ctx) {
      return await AdminTicketCategoryService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableTicketCategories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onTableTicketCategoryRefresh () {
      this.$refs.tableTicketCategories.refresh()
    },

    onShowAddForm () {
      this.state.editing = false
      this.ticket_category.id = null
      this.ticket_category.category_name = null
      this.ticket_category.active = 0
      this.$bvModal.show('modal-ticket-category')
    },

    onShowEditForm (ticketCategory) {
      this.state.editing = true
      this.ticket_category.id = ticketCategory.id
      this.ticket_category.category_name = ticketCategory.category_name
      this.ticket_category.active = ticketCategory.active
      this.$bvModal.show('modal-ticket-category')
    },

    async onValidateFormTicketCategory (bvModal) {
      bvModal.preventDefault()
      this.$refs.formTicketCategory.validate().then(
        isAllFieldsAreValid => {
          if (isAllFieldsAreValid) {
            let text = 'Do you really want to create ticket category?'
            if (this.state.editing) {
              text = 'Do you really want to update ticket category?'
            }
            this.$swal.fire({
              icon: 'question',
              title: 'Question',
              text: text,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Yes',
              cancelButtonColor: '#FF2929',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutTicketCategory()
                } else {
                  return this.onPostTicketCategory()
                }
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk(
              'Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              }
            )
          }
        }
      )
    },

    async onPostTicketCategory () {
      return new Promise(resolve => {
        AdminTicketCategoryService.post(this.ticket_category).then(({ data: { message } }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-ticket-category')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.onTableTicketCategoryRefresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formTicketCategory.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutTicketCategory () {
      return new Promise(resolve => {
        AdminTicketCategoryService.put(this.ticket_category).then(({ data: { message, ticketCategory } }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-ticket-category')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableTicketCategories.localItems, { id: ticketCategory.id })
            row.active = ticketCategory.active
            row.category_name = ticketCategory.category_name
            row.created_at = ticketCategory.created_at
            row.updated_at = ticketCategory.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formTicketCategory.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
